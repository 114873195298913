import React, { useRef, useState } from 'react';
import "./Quote.css";
import { generatePdf } from './pdf-helper';

const Quotations = () => {
    const iframeRef = useRef(null);

    const [formData, setFormData] = useState({
        project: '',
        date: '',
        description: '',
        numberProposals: 1,
        proposals: [{ chars: [], value: '', duration: '' }],
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        generatePdf(iframeRef,formData)
    };

    const handleInputChange = (event, index, type) => {
        const { name, value } = event.target;
        if (type === 'proposals') {
            if (name === 'chars') {
                const charsArray = value.split('\n').map(str => str.trim());
                const newproposals = [...formData.proposals];
                newproposals[index] = { ...newproposals[index], [name]: charsArray };
                setFormData((prevData) => ({ ...prevData, proposals: newproposals }));
            } else {
                const newproposals = [...formData.proposals];
                newproposals[index] = { ...newproposals[index], [name]: value };
                setFormData((prevData) => ({ ...prevData, proposals: newproposals }));
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handlenumberProposalsChange = (event) => {
        const newNumberProposals = parseInt(event.target.value, 10) || 1;
      
        setFormData((prevData) => {
          const existingProposals = prevData.proposals.slice(0, newNumberProposals);
      
          const newProposals = Array.from(
            { length: newNumberProposals - existingProposals.length },
            (_, index) => ({
              chars: [],
              value: '',
              duration: '',
            })
          );
      
          return {
            ...prevData,
            numberProposals: newNumberProposals,
            proposals: [...existingProposals, ...newProposals],
          };
        });
      };




    return (
        <div className='quoteWrapper'>
            <form onSubmit={handleSubmit} className='formQuote'>
                <label>
                    Titulo del proyecto
                    <input
                        autoComplete="off"
                        className="inputStyle"
                        type="text"
                        name="project"
                        value={formData.project}
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    Fecha de cotización
                    <input
                        autoComplete="off"
                        className="inputStyle"
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    Descripción de la propuesta
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        rows={8}
                    />
                </label>
                <label>
                    Número de propuestas
                    <input
                        autoComplete="off"
                        type="number"
                        name="numberProposals"
                        value={formData.numberProposals}
                        onChange={handlenumberProposalsChange}
                    />
                </label>
                {[...Array(formData.numberProposals)].map((_, index) => (
                    <div key={index} className='proposalWrapper'>
                        <h1>Propuesta #{index + 1}</h1>
                        <br />
                        <label>
                            Costo
                            <input
                                autoComplete="off"
                                type="text"
                                name="value"
                                value={formData.proposals[index].value}
                                onChange={(e) => handleInputChange(e, index, 'proposals')}
                            />
                        </label>

                        <label>
                            Duracion en dias
                            <input
                                autoComplete="off"
                                type="text"
                                name="duration"
                                value={formData.proposals[index].duration}
                                onChange={(e) => handleInputChange(e, index, 'proposals')}
                            />
                        </label>

                        <label>
                            Caracteristicas <span className='spanQuote'>(Separe por salto de linea cada caracteristica)</span>
                            <textarea
                                name="chars"
                                value={formData.proposals[index].chars.join('\n')}
                                onChange={(e) => handleInputChange(e, index, 'proposals')}
                                rows={8}
                            />

                        </label>
                    </div>
                ))}
                <button type="submit" className='secondary-button'>Vista Previa</button>
            </form>

            <iframe ref={iframeRef} title="PDF Preview" width="100%" height="500px" />

        </div>
    );
};

export default Quotations;
