import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import "./App.css";
import Login from './Components/Login';
import MainPage from "./Components/MainPage";
import Footer from "./Components/Footer"
import Quotations from "./Components/Quotations"

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/admin" element={<Login/>} />
          <Route path="/quotations" element={<Quotations/>} />
        </Routes>
        <Footer />

      </div>
    </Router>
  );
}

export default App;