import React from 'react';
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import './Main.css'; // Create this file for styling
import Testimonial from "./Testimonial";
import Work from "./Work";

const MainPage = () => {

    return (
        <div>
            <Home />
            <About />
            <Work />
            <Testimonial />
            <Contact />
        </div>
    );
};

export default MainPage;
