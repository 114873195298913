import jsPDF from 'jspdf';
import { montserratBoldFont } from '../../Assets/fonts/Monserrat-bold';
import { montserratRegularFont } from '../../Assets/fonts/Monserrat-regular';
import { nunitoBoldFont } from '../../Assets/fonts/Nunito-Sans-bold';
import { nunitoRegularFont } from '../../Assets/fonts/Nunito-Sans-normal';
import template2 from "../../Assets/quote-template-addiontal-page.png";
import template from "../../Assets/quote-template.png";

const addFontsToPdf = (pdf) => {
    pdf.addFileToVFS('NunitoSans-Regular.ttf', nunitoRegularFont);
    pdf.addFileToVFS('NunitoSans-Bold.ttf', nunitoBoldFont);
    pdf.addFileToVFS('Monserrat-Regular.ttf', montserratRegularFont);
    pdf.addFileToVFS('Monserrat-Bold.ttf', montserratBoldFont);

    pdf.addFont('NunitoSans-Regular.ttf', 'Nunito-Sans', 'normal');
    pdf.addFont('NunitoSans-Bold.ttf', 'Nunito-Sans', 'bold');
    pdf.addFont('Monserrat-Regular.ttf', 'Monserrat', 'normal');
    pdf.addFont('Monserrat-Bold.ttf', 'Monserrat', 'bold');
};

const addImage = (pdf, imageData) => {
    pdf.addImage(imageData, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
}

const createPage = (pdf, start) => {
    pdf.addPage();
    pdf.addImage(template2, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
    return 30;
}

const createTitle = (pdf,formData) => {
    pdf.setFontSize(20);
    pdf.setFont('Monserrat', 'bold');
    pdf.text(formData.project, 80, 20);

    pdf.setFont('Nunito-Sans', 'normal');
    pdf.setFontSize(11);
    pdf.text(`Fecha: ${formData.date}`, 110, 28);
}

const createDescription = (pdf,formData) => {
    pdf.setFont('Nunito-Sans', 'bold');
    pdf.setFontSize(15);
    pdf.text("Descripción", 25, 50);

    pdf.setFont('Nunito-Sans', 'normal');
    pdf.setFontSize(11);
    const text = formData.description
    const lines = pdf.splitTextToSize(text, 165);
    const yPosition = 58;

    lines.forEach((line, index) => {
        pdf.text(line, 25, yPosition + index * 5);
    });

}

const createChars = (pdf,formData) => {
    let start = 80;

    formData.proposals.forEach((propuesta, index) => {
        if (start > 280) {
            start = createPage(pdf, start)
        }

        pdf.setFont('Nunito-Sans', 'bold');
        pdf.setFontSize(18);
        pdf.text(`Propuesta #${index + 1}`, 25, start);
        pdf.setFontSize(11);
        start += 8;
        pdf.text(`Costo: ${propuesta.value}`, 30, start);
        start += 5;
        pdf.text(`Duración: ${propuesta.duration} días`, 30, start);
        start += 5;
        pdf.setFont('Nunito-Sans', 'normal');
        pdf.text("Características:", 30, start);
        start += 7;

        propuesta.chars.forEach(char => {
            pdf.text(`- ${char}`, 35, start);
            start += 5;

            if (start > 275) {
                start = createPage(pdf, start)
            }
        })

        start += 10;
    })

    if (start > 275) {
        start = createPage(pdf, start)
    }

    pdf.setFont('Nunito-Sans', 'bold');
    pdf.setFontSize(10);
    pdf.text(`*NOTA: el tiempo de entrega puede variar dependiendo de las disponibilidad de la información`, 25, start);

    start += 5;
    pdf.text(`brindada por el cliente`, 25, start);
    start += 12;

    if (start > 275) {
        start = createPage(pdf, start)
    }

    pdf.setFontSize(18);
    pdf.setFont('Monserrat', 'bold');
    pdf.text("MÉTODOS DE PAGO", 70, start);
    pdf.setFont('Nunito-Sans', 'normal');
    pdf.setFontSize(12);

    start += 8
    pdf.text("Transferencia Bancaria, Pago en Efectivo o Pago a Nequi", 50, start);
}


export const generatePdf = (iframeRef, formData) => {
    const pdf = new jsPDF();
    addFontsToPdf(pdf);
    addImage(pdf, template);
    createTitle(pdf,formData);
    createDescription(pdf,formData)
    createChars(pdf,formData)
    const pdfDataUri = pdf.output('datauristring');
    if (iframeRef.current) {
        iframeRef.current.src = pdfDataUri;
    }
};